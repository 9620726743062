import React, { useEffect, useState, useCallback } from 'react';

export const usePortfolioData = () => {
    const apiServer = process.env.REACT_APP_API_SERVER || 'https://api.waltthirion.com'
    // const auth_token = `Bearer 1f8ea7acaed62ce0b5649ecaae70f957`;
	const publicApiAccessToken = process.env.REACT_APP_WTP_DAM_API_KEY

    const [portfolios, setPortfolios] = useState({
        tree: [],
        list: [],
        apiSrvr: apiServer
    });

    const slctPortChildren = useCallback((port) => {
        if (!port.isSet || !port.children || port.children.length === 0) {
            return port;
        }

        // port is a published collection set and it has children
        let portChildren = port.children.filter(child => child.display);
        port.children = portChildren.map((child) => {
            if (child.isSet && child.children && child.children.length > 0) {
                return slctPortChildren(child);
            }
            return child;
        });
        port.children = port.children.sort((c1, c2) => c1.sort - c2.sort);
        return port;
    }, []);

    const wtpPortData = useCallback(async (abortController) => {
        try {
            const res = await fetch(`${apiServer}/api/wtp-db/portfolio`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${publicApiAccessToken}`
                }
            });
            if (res.ok && !abortController.signal.abort) {
                return await res.json();
            } else {
                return null
            }
        } catch (err) {
            console.error(err.message);
            return null;
        }
    }, [apiServer, publicApiAccessToken]);

    const wtpHierarchy = useCallback((portList) => {
        if (!portList || portList.length === 0) return {pList: [], hList: []};

        portList.forEach(portfolio => {
            if (portfolio.isSet && !portfolio.children)
                portfolio.children = []
        })

        let children = portList
            .filter((port) => port.parents.length > 0)

        // add the child portfolios to the parent(s)
        children.forEach((childPortfolio) => {
            const curParentId = childPortfolio.parents[childPortfolio.parents.length - 1].remoteCollectionId       // only care about the immediate parent
            let parentIdx = portList.findIndex(({_id}) => _id === curParentId)
            if (parentIdx < 0) {
                console.error(`Orphaned portfolio: ${childPortfolio.name}, _id: ${childPortfolio._id}}`, childPortfolio);
            } else {
                let newParent = portList[parentIdx]
                if (!newParent.children) {
                    newParent.children = [];
                }
                newParent.children.push(childPortfolio);         // add this child to the parent
                portList.splice(parentIdx, 1, newParent)
            }
        });
        const parents = portList.filter((port) => port.parents.length === 0)

        return {pList: portList, hList: parents};
    }, []);


    const sortPortfolios = useCallback((portList) => {
        if (!portList) {
            console.error(`sortPortfolios: returning null array`);
            return [];
        }
        portList.sort((e1, e2) => {
            return e1.sort - e2.sort;
        });
        return portList;
    }, []);

    const pruneTree = useCallback(
        (h) => {
            if (!h) return null;
            let newH = h.filter((port) => port.display);
            newH = newH.map((port) => {
                return slctPortChildren(port);
            });
            return newH;
        },
        [slctPortChildren]
    );

    useEffect(() => {
        const getPortData = async (abortController) => {
            const portList = await wtpPortData(abortController);
            const {pList, hList} = wtpHierarchy(portList)
            const pData = sortPortfolios(pList);
            const hData = pruneTree(sortPortfolios(hList));
            // console.log(`hData: `, hData)

            setPortfolios({
                tree: hData,
                list: pData,
                apiSrvr: apiServer
            });
        };

        const abortController = new AbortController();
        getPortData(abortController);
		return () => abortController.abort();
    }, [apiServer, pruneTree, sortPortfolios, wtpPortData, wtpHierarchy]);

    return portfolios;
};

const PortfolioContext = React.createContext({ tree: [], list: [] });

export default PortfolioContext;
