import React, { useEffect, Suspense, lazy } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';


import NotFound from './NotFound';
import _404 from './_404';

const Article = lazy(() => import('./Article'));
const Articles = lazy(() => import('./Articles'));
// const CheckOut = lazy(() => import('./CheckOut'));
const Home = lazy(() => import('./Home'));
const ImageProductPage = lazy(() => import('./ImageProductPage'));
const ContactForm = lazy(() => import('./ContactForm'));
const LandingPage = lazy(() => import('./LandingPage'));
const Page = lazy(() => import('./Page'));
const PortfolioPage = lazy(() => import('./PortfolioPage'));
const SearchPage = lazy(() => import('./SearchPage'));
// const ShoppingCartPage = lazy(() => import('./ShoppingCart'));
const SpecialEdition = lazy(() => import('./SpecialEdition'));
const TestComponent = lazy(() => import('./TestComponent'));

const Routes = () => {
    const location = useLocation();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Pageview',
            'pagePath': location.pathname
        });
    }, [location]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>

                {/* StoryBlok Experiments */}
                <Route path="/test" exact component={TestComponent} />
                <Route path="/pages/test" exact component={TestComponent} />

                {/* Home and other regular pages */}
                <Route path="/" exact component={Home} />
                <Route path="/home" exact component={Home} />
                <Route path="/pages" exact component={Home} />

                {/* Portfolios */}
                <Route path="/portfolios/:portSlug" exact component={PortfolioPage} />
                <Route path="/portfolios/:portSlug/:imgSlug" exact component={ImageProductPage} />
                <Route path="/images/:imgSlug" exact component={ImageProductPage} />

                {/* Special Editions, General Pages, and misc */}
                <Route path="/special-editions" exact component={SpecialEdition} />
                <Route path="/special-editions/:slug" component={SpecialEdition} />
                <Route path="/about" exact component={Page} />
                <Route path="/acquire-prints" exact component={Page} />
                {/* <Route path="/cart" exact component={ShoppingCartPage} />
                <Route path="/checkout" exact component={CheckOut} /> */}
                <Route path="/protect-these-lands" component={Page} />
                <Route path="/search" component={SearchPage} />
                <Route path="/contact" component={ContactForm} />
                <Route path="/donate-thank-you" component={Page} />
                <Route path="/copyright-statement" exact component={Page} />
                <Route path="/terms-and-conditions" component={Page} />
                <Route path="/privacy-policy" exact component={Page} />

                {/* landing page handler */}
                <Route path="/cta/:slug" component={LandingPage} />

                {/* test page for development */}
                <Route path="/pages/cta-test" exact component={TestComponent} />

                {/* default page handler */}
                <Route path="/pages/:slug" component={Page} />

                {/* Articles (Blog Posts) */}
                <Route path="/articles" exact component={Articles} />
                <Route path="/articles/:slug" exact component={Article} />

                <Route path="/404" component={_404} />
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
